body {
  margin: 0;
}

body.active-modal {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
